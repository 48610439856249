<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('external_sidebar.training_course_evaluation') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="6">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
                        <b-row>
                            <b-col lg="8" sm="8">
                                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="fiscal_year_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('elearning_config.fiscal_year')}}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.fiscal_year_id"
                                        :options="fiscalYearList"
                                        id="fiscal_year_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="4" sm="4">
                              <b-button type="submit" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                            </b-col>
                        </b-row>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.training_course_evaluation') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(training_date)="data">
                                          <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(view)="data">
                                          <router-link v-if="data.item.storeData === 1" :to="`training-course-evaluation-details?circularMemoNo=${data.item.circular_memo_no}`" :title="$t('externalTraining.course_evaluation') + ' ' + $t('globalTrans.details')" :class="'btn_table_action table_action_view sm'"><i class="fas fa-eye"></i></router-link>
                                            <router-link :to="`training-course-evaluation-form?circularMemoNo=${data.item.circular_memo_no}&batchNo=${data.item.batch_no}`" :title="$t('externalTraining.course_evaluation') + ' ' + $t('globalTrans.entry')" :class="'btn_table_action table_action_view sm'"><i class="fas fa-address-card"></i></router-link>
                                            <!-- <router-link v-if="storeData === 1" :to="`training-course-evaluation-form?circularMemoNo=${data.item.circular_memo_no}&batchNo=${data.item.batch_no}`" :title="$t('externalTraining.course_evaluation') + ' ' + $t('globalTrans.entry')" :class="'btn_table_action table_action_view sm'"><i class="fas fa-address-card"></i></router-link> -->
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button variant="iq-bg-info" size="sm" @click="remove(data.item)" ><i class="far fa-check-square m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="$t('external_sidebar.training_course_evaluation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeCourseEvaluationList, trainerEvaluationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
const today = new Date().toISOString().substr(0, 10)
export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
          search: {
            fiscal_year_id: 0,
            org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          personalInfoList: []
        }
    },
    computed: {
      fiscalYearList: function () {
          return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0).map(obj => {
              if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
              } else {
              return { value: obj.value, text: obj.text_en }
              }
          })
        },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
        ]
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
              // { label: this.$t('externalTraining.trainer_name'), class: 'text-left' },
              { label: this.$t('externalTraining.evaluation_info'), class: 'text-left' }

          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'circular_memo_no' },
              { key: 'batch_bn' },
              // { key: 'trainer_name_bn' },
              { key: 'view' }
            ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'batch' },
              // { key: 'trainer_name' },
              { key: 'view' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList
      }
    },
    async created () {
      this.search.fiscal_year_id = this.getCurrentFiscalYearId(this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0))
      this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
      getCurrentFiscalYearId (fiscalYearList) {
        if (fiscalYearList.length === 0) {
          return
        }
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        /** Define the year position  */
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        let currentFiscalYearId = 0
        fiscalYearList.forEach(element => {
          if (element.text_en.indexOf(yearStr) === yearPosition) {
            currentFiscalYearId = element.value
          }
        })
        return currentFiscalYearId
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, trainerEvaluationToggleStatus, item)
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, traineeCourseEvaluationList, params).then(response => {
            if (response.success) {
              this.personalInfoList = response.personalInfoList
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data, response.traineeCourseEvaluation))
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data, courseEvaluation) {
        const listData = data.map(item => {
          const checkData = courseEvaluation.find(data => data.circular_memo_no === item.circular_memo_no)
          const checkStore = {}
          if (checkData) {
            checkStore.storeData = 1
          }
          if (item.training_end_date <= today) {
            checkStore.checkEvaluate = 1
          }
          const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const trainerObj = this.personalInfoList.find(doc => doc.value === parseInt(item.trainer_id))
          const trainerData = {}
          if (typeof trainerObj !== 'undefined') {
            trainerData.trainer_name = trainerObj.text_en
            trainerData.trainer_name_bn = trainerObj.text_bn
          } else {
            trainerData.trainer_name = ''
            trainerData.trainer_name_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          // item.trainer_evaluation_marks = item.trainer_evaluation_marks.map(trainerEvaluationMark => {
          //   const trainerEvaluation = this.trainerEvaluationList.find(trainerEvaluationObj => trainerEvaluationObj.value === parseInt(trainerEvaluationMark.trainer_evaluation_detail_id))
          //   const customItem = {
          //     evaluation_name: trainerEvaluation?.text_en,
          //     evaluation_name_bn: trainerEvaluation?.text_bn
          //   }
          //   return Object.assign({}, trainerEvaluationMark, customItem)
          // })
          return Object.assign({}, item, orgData, checkStore, batchData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, trainerData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
